<template>
  <div class="main-block">
    <div class="main-block-title first">Business partners</div>
    <div class="main-block-content">

      <ul v-if="summaryErrors.length" class="summary">
        <li class="error" v-for="error in summaryErrors" :key="error">{{ error }}</li>
      </ul>

      <partner @saved="reloadPartners"/>

      <div class="custom-hr"><span>Partners lists</span></div>

      <div class="grid">
        <div>
          <div class="main-block-title" style="font-size: 16px">Incoming payments</div>
          <div>

            <transition-group name="fade">
              <div class="partner" v-for="(partner, index) in incoming" :key="partner.id" :data-test="'partner-incoming-' + index">
                <div class="partner-name">{{ partner.name }}</div>
                <div class="partner-country">{{ getCountryName(partner.country_id) }} ({{ partner.country_id }})</div>
                <div class="partner-description">{{ partner.description }}</div>
                <ul class="partner-files">
                  <li v-for="file in partner.files" :key="file.name">
                    <span class="partner-files-type">{{ getFileType(file.type) }}:</span>
                    <a :href="file.url" target="_blank">{{ file.original_name }}</a>
                  </li>
                </ul>

                <icon-close class="partner-delete" @click.native="removeItem(partner.id)" data-test="remove"/>
              </div>
            </transition-group>

            <div class="partner-no" v-if="!incoming.length">
              No business partners added
            </div>
          </div>
        </div>

        <div>
          <div class="main-block-title" style="font-size: 16px">Outgoing payments</div>
          <div>

            <transition-group name="fade">
              <div class="partner" v-for="(partner, index) in outgoing" :key="partner.id" :data-test="'partner-outgoing-' + index">
                <div class="partner-name">{{ partner.name }}</div>
                <div class="partner-country">{{ getCountryName(partner.country_id) }} ({{ partner.country_id }})</div>
                <div class="partner-description">{{ partner.description }}</div>
                <ul class="partner-files">
                  <li v-for="file in partner.files" :key="file.name">
                    <span class="partner-files-type">{{ getFileType(file.type) }}:</span>
                    <a :href="file.url" target="_blank">{{ file.original_name }}</a>
                  </li>
                </ul>

                <icon-close class="partner-delete" @click.native="removeItem(partner.id)" data-test="remove"/>
              </div>
            </transition-group>

            <div class="partner-no" v-if="!outgoing.length">
              No business partners added
            </div>
          </div>
        </div>
      </div>

      <div class="grid-full-line form-buttons text-right">
        <button type="submit" class="btn-next" :disabled="isNextDisabled" @click="submit" data-test="submit">
          <span>Save and proceed</span>
          <icon-next color="#19191C" size="15"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IconNext from "@/assets/icons/IconNext";
import Partner from "@/views/business/Partner";
import {mapState} from "vuex";
import IconClose from "@/assets/icons/IconClose";

export default {
  name: 'Partners',
  components: {
    IconClose,
    IconNext,
    Partner
  },
  data () {
    return {
      items: []
    }
  },
  computed: {
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      countries: state => state.init.countries,
      fileTypeList: state => state.init.counterpartyFileType
    }),
    incoming () {
      return this.items.filter(i => {
        return i.type === 'INCOMING_PAYMENTS'
      })
    },
    outgoing () {
      return this.items.filter(i => {
        return i.type === 'OUTGOING_PAYMENTS'
      })
    },
    isNextDisabled () {
      return this.incoming.length < 1
          || this.outgoing.length < 1
    },
    summaryErrors () {
      let errors1 = Object.values(this.registrationData.counterparty.errors['INCOMING_PAYMENTS'] || [])
      let errors2 = Object.values(this.registrationData.counterparty.errors['OUTGOING_PAYMENTS'] || [])

      return errors1.concat(errors2)
    }
  },
  mounted() {
    if (this.registrationData.counterparty) {
      this.loadPartners()
    }
  },
  methods: {
    getFileType (id) {
      const cl = this.fileTypeList.find(i => i.id === id)
      return cl ? cl.label : ''
    },
    reloadPartners () {
      this.$store.dispatch('updateRegistration').then(() => {
        this.loadPartners()
      })
    },
    loadPartners () {
      this.items = this.registrationData.counterparty.items.map(i => {
        return i.attributes
      }) || []
    },
    removeItem (id) {
      this.$sdk.registration.counterparty.delete(this.registrationHash, id).then(() => {
        this.reloadPartners()
      })
    },
    getCountryName (id) {
      const name = this.countries.find(i => i.id === id)
      return name ? name.name : ''
    },
    submit () {
      this.$emit('next-step')
    }
  }
}
</script>


<style lang="less">
.partner {
  margin-top: 20px;
  position: relative;

  background: #f7f7f7;
  padding: 10px;
  border-radius: 3px;

  &:first-child {
    margin-top: 0;
  }

  &-name {
    font-weight: 500;
    padding-right: 30px;
    line-height: 1.4em;
  }
  &-country {
    margin: 10px 0;
    color: #666;
    font-size: 15px;
  }
  &-description {
    color: #666;
    line-height: 1.5em;
    font-size: 15px;
    word-break: break-word;
  }
  &-delete {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  &-files {
    margin-top: 15px;

    &-type {
      color: #666;
      margin-right: 10px;
    }

    li {
      list-style-position: inside;
      list-style-type: decimal;

      margin-bottom: 5px;

      a {
        color: #19191C;
        word-break: break-word;
      }
    }
  }

  &-no {
    padding: 20px;
    border: 1px dashed #eee;
    text-align: center;
    color: #666;
    line-height: 1.65em;
  }
}
</style>
