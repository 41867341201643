<template>
  <div class="grid grid-column-1" style="border-bottom: 1px solid #f7f7f7;">

    <div class="grid grid-template-3" style="margin-bottom: 0">
      <form-group label="Company name" :value="value.name" :errors="errors.name">
        <input data-test="company_name" v-model="value.name" class="form-control" @change="validate(['name'])" />
      </form-group>

      <form-group label="Country of registration" :value="value.country_id" :errors="errors.country_id">
        <v-select data-test="country_id" v-model="value.country_id" :reduce="option => option.id" :options="countriesSorted" label="label" class="form-control" @option:selected="validate(['country_id'])"/>
      </form-group>

      <form-group label="Type" :value="value.type" :errors="errors.type">
        <v-select data-test="type" v-model="value.type" :options="typeList" :reduce="option => option.id" label="label" class="form-control" @option:selected="validate(['type'])"></v-select>
      </form-group>
    </div>

    <file-upload data-test="files" v-model="value.files" :types="fileTypeList" @change="validate(['files'])" :errors="errors" />

    <form-group label="Description of the business relationship" :value="value.description" :errors="errors.description">
      <textarea data-test="description" v-model="value.description" class="form-control"  @change="validate(['description'])" />
    </form-group>

    <button class="btn btn-yellow" style="margin: 0;" :disabled="disable" @click="save" data-test="save">
      + Add
    </button>

  </div>
</template>

<script>
import FormGroup from "@/components/FormGroup";
import {mapGetters, mapState} from "vuex";
import FileUpload from "@/components/FileUpload";

export default {
  name: 'Partner',
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          name: '',
          type: '',
          description: '',
          country_id: '',
          files: []
        }
      }
    }
  },
  components: {
    FileUpload,
    FormGroup
  },
  computed: {
    ...mapState({
      registrationHash: state => state.registration.hash,
      registrationData: state => state.registration.data,

      countries: state => state.init.countries,
      typeList: state => state.init.counterpartyType,
      fileTypeList: state => state.init.counterpartyFileType
    }),
    ...mapGetters({
      countriesSorted: 'listSorted'
    }),
    formData () {
      return {
        name: this.value.name,
        type: this.value.type,
        description: this.value.description,
        country_id: this.value.country_id,
        files: this.value.files
      }
    }
  },
  data () {
    return {
      errors: [],
      disable: false
    }
  },
  methods: {
    save () {
      this.disable = true
      this.$sdk.registration.counterparty.create(this.registrationHash, this.formData, 0).then(response => {
        if (Object.keys(response.data).length === 0) {
          this.$emit('saved')
          this.reset()
        } else {
          this.errors = response.data
        }
        this.disable = false
      })
    },
    reset () {
      this.value.name = ''
      this.value.type = ''
      this.value.description = ''
      this.value.country_id = ''
      this.value.files = [{}]
    },
    validate (attributes = []) {

      for (let name of ['files']) {
        let filesIndex = attributes.indexOf(name)
        if (filesIndex !== -1) {
          for (let i in this.formData[name]) {
            attributes.push(name + '[' + i + '][type]')
            attributes.push(name + '[' + i + '][name]')
            attributes.push(name + '[' + i + '][original_name]')
          }
        }
      }

      this.$sdk.registration.counterparty.create(this.registrationHash, this.formData, 1).then(response => {
        for (const attr of attributes) {
          if (response.data[attr] !== undefined) {
            this.$set(this.errors, attr, response.data[attr])
          } else if (this.errors[attr] !== undefined) {
            this.$delete(this.errors, attr)
          }
        }
      })
    }
  }
}
</script>
